import { render, staticRenderFns } from "./rockiot.number.box.vue?vue&type=template&id=7952c68c&scoped=true&"
import script from "./rockiot.number.box.vue?vue&type=script&lang=js&"
export * from "./rockiot.number.box.vue?vue&type=script&lang=js&"
import style0 from "./rockiot.number.box.vue?vue&type=style&index=0&id=7952c68c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7952c68c",
  null
  
)

export default component.exports